.pgc-page1 {
  .title {
    position: relative;
    margin-top: 100px;
    text-align: center;
  }

  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .custom-table {
      width: 851px;
      border-collapse: collapse;
      margin-top: 35px;
      box-shadow: 0 0 10px rgb(122, 122, 122);

      .custom-cell {
        border: 1px solid #ddd;
        background-color: #f5f5f5;
        
        &:hover{
          background-color: #eee;
        }

        td:first-child {
          width: 150px;
          padding: 15px;
        }

        td:last-child {
          input[type="text"] {
            width: calc(100% - 20px);
          }
        }

        input,
        select {
          padding: 10px 5px;
          border-radius: 5px;
          border: 1px solid #ddd;
          &:focus {
            border-color: #ff7f50;
            outline: none;
          }
        }
      }

      .custom-cell-title {
        border: 1px solid #01a467;
        text-align: center;
        color: #fff;
        background-color: #01a467;

        .custom-cell-label {
          padding: 15px;
        }
      }
    }

    .imgs {
      display: flex;
      align-items: center;
      margin-top: 20px;
      flex-wrap: wrap;
      justify-content: center;

      .img-item {
        position: relative;
        margin: 0 10px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .item-delete {
          color: "white";
          width: 32px;
          height: 32px;
          background-color: rgba(190, 187, 187, 0.541);
        }

        .item-delete:hover {
          color: "white";
          width: 32px;
          height: 32px;
          background-color: rgb(253, 181, 142);
        }

        p {
          margin-top: 10px;
        }
      }
    }
  }

  .buttons-container {
    display: flex;
    justify-content: center;
    margin: 10px auto;
    padding-bottom: 30px;

    button {
      margin: 0 5px;
      background-color: #ff7f50;

      &:hover {
        background-color: #ff9b61;
      }
    }
  }
  
  button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  .genPDF {
    position: absolute;
    right: 5vw;
    top: 25vh;
    .numPages {
      font-size: 20px;
      text-align: right;
    }
    .closeIcon {
      float: right;
      position: absolute;
      z-index: 10;
      right: 0px;
    }
    button {
      background-color: transparent;
    }
    .loadingPdf svg {
      height: 200px;
      margin: -500px -180px;
    }
    .react-pdf__Page__annotations {
      display: none;
    }
  }
}
