.calendar {
    position: absolute;
    width: 16.6%;
    height: 246px;
    left: 19.96%;
    top: 50px;
    text-align: center;
    border: solid 1px black;
    border-left: none;
    border-right: none;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 14px;

    > .titrecal{
        display: block;
        position: relative;
        color: white;
        text-align: center;
        background-color: #ff7f50;
        bottom: 20px;
        border-bottom: solid 1px black;
        width: auto;
        bottom:0;
        top:0;
        left:0;
        right:0;
        padding: 10px;
    
    }

    > span{
        position: relative;
        font-size: 50px;
        top: 0px;
    }
    .react-calendar{
        background: transparent;
    }
    .react-calendar__tile{
        padding: 4.2px;
    }
    .react-calendar__tile--now{
        color: white;
        background-color: orange;
    }
}
@media(max-width:1400px){
    .calendar{
        width: 25%;
        left: 25%;
    }
}
@media(max-width:900px){
    .calendar{
        .react-calendar .react-calendar__navigation button{
            min-width: 20px;
        }
    }
}
@media(max-width:700px){
    .calendar{
        width: 50%;
        left: 50%;
    }
}