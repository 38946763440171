// Colors
$primary-color: #1976d2;
$secondary-color: #f5f5f5;
$border-color: #e0e0e0;
$text-color: #333333;
$hover-color: #e8f0fe;

// Spacing
$spacing-xs: 4px;
$spacing-sm: 8px;
$spacing-md: 16px;
$spacing-lg: 24px;
$spacing-xl: 32px;

// Typography
$font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
$font-size-sm: 14px;
$font-size-md: 16px;
$font-size-lg: 20px;

// Borders
$border-radius: 4px;
$border-width: 1px;

// Transitions
$transition-duration: 0.2s;

// Breakpoints
$breakpoint-tablet: 768px;
$breakpoint-desktop: 1024px;