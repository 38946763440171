.diuo-page9 {
  .title {
    position: relative;
    margin-top: 100px;
    text-align: center;
  }

  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .custom-table {
      width: 851px;
      border-collapse: collapse;
      margin-top: 25px;
      box-shadow: 0 0 10px rgb(122, 122, 122);

      .custom-cell {
        border: 1px solid #ddd;
        background-color: #f5f5f5;

        &:hover {
          background-color: #eee;
        }

        td {
          padding: 15px;
          input[type="text"] {
            width: 98%;
            padding: 10px 6px;
            border-radius: 5px;
            border: 1px solid #ddd;
            &:focus {
              border-color: #ff7f50;
              outline: none;
            }
          }
        }

        input[type="checkbox"] {
          transform: scale(1.25);
          -webkit-transform: scale(1.25);
          -moz-transform: scale(1.25);
          -ms-transform: scale(1.25);
          -o-transform: scale(1.25);
        }

        textarea {
          width: 98%;
          height: 100px;
          padding: 5px;
          border-radius: 5px;
          resize: none;
          border: 1px solid #ddd;
          &:focus {
            border-color: #ff7f50;
            outline: none;
          }
        }

        input[type="file"] {
          padding: 10px;
          border-radius: 5px;
          border: 1px solid #ddd;
          &:focus {
            border-color: #ff7f50;
            outline: none;
          }
        }
      }

      .custom-cell-title {
        border: 1px solid #01308d;
        text-align: center;
        color: #fff;
        background-color: #01308d;
        .custom-cell-label {
          padding: 20px;
        }
      }
    }

    .img-item {
      position: relative;
      margin-top: 15px;
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;
      justify-content: center;

      .item-delete {
        color: "white";
        width: 32px;
        height: 32px;
        background-color: rgba(190, 187, 187, 0.541);
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
        &:hover {
          color: "white";
          width: 32px;
          height: 32px;
          background-color: rgb(253, 181, 142);
        }
      }

      p {
        margin: 5px 0 -8px;
      }
    }
  }
  .buttons-container {
    display: flex;
    justify-content: center;
    padding: 15px 0 30px 0;

    button {
      margin: 0 5px;
      background-color: #ff7f50;

      &:hover {
        background-color: #ff9b61;
      }
    }
  }

  button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }

  .genPDF {
    position: absolute;
    right: 5vw;
    top: 25vh;
    .numPages {
      font-size: 20px;
      text-align: right;
    }
    .closeIcon {
      float: right;
      position: absolute;
      z-index: 10;
      right: 0px;
    }
    button {
      background-color: transparent;
    }
    .loadingPdf svg {
      height: 200px;
      margin: -500px -180px;
    }
    .react-pdf__Page__annotations {
      display: none;
    }
  }
}
