.popup-overlay {
  .genPDF {
    position: absolute;
    right: 5vw;
    top: 25vh;
    .numPages {
      font-size: 20px;
      text-align: right;
    }
    .closeIcon {
      float: right;
      position: absolute;
      z-index: 10;
      right: 0px;
    }
    button {
      background-color: transparent;
    }
    .loadingPdf svg {
      height: 200px;
      position: absolute;
      top: 20vh;
      margin: 0 -130px;
    }
  }
  .popup-content {
    background-color: transparent;
    border: none;
  }
}
.quotaH {
  position: absolute;
  width: 100%;
  h1 {
    margin-top: 70px;
    text-align: center;
  }
  button {
    background-color: #ff7f50;
  }
  .ndossier {
    position: static;
    margin-top: 10px;
    margin-left: 5rem;
  }
  .code {
    position: relative;
    margin-top: 50px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    div {
      margin-bottom: 10px;
      margin-left: 20px;
    }
    input {
      float: right;
      margin-right: 78vw;
    }
  }
  .heures {
    display: flex;
    div {
      margin-left: 20px;
    }
    input {
      margin-left: 10px;
    }
    button {
      margin-left: 10px;
    }
  }
  .quota {
    margin-left: 20px;
    margin-bottom: 10px;
  }
  .differences {
    margin-left: 20px;
    div {
      margin-bottom: 10px;
    }
    input {
      float: right;
      margin-right: 75vw;
    }
  }
  .quotaTables {
    margin: 25px auto auto 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .quotaTableRea {
      margin-left: 20px;
    }
    .ReactTable {
      width: fit-content;
      font-size: 15px;
      .rt-td {
        svg {
          height: 20px;
        }
      }
      button {
        background-color: transparent;
      }
    }
  }

  .ReactTable .rt-tbody .rt-tr-group {
    text-align: center;
  }

  .quotaTableCon,
  .quotaTableRea {
    margin: 0 20px 20px 20px;
  }
  .task-table {
    margin-top: 20px;
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .MuiInput-input {
      text-align: center;
    }
    .subtable-btn {
      display: flex;
      justify-content: flex-end;
      .btn-valid {
        color: white;
        background-color: #ff7f50;
        margin: 20px 10px 0 auto;
      }
      .btn-reset {
        color: white;
        background-color: #f5b039;
        margin: 20px 0 0 0;
      }
      .btn-valid:hover {
        background-color: #ff9b61;
      }
      .btn-reset:hover {
        background-color: #ffbf50;
      }
    }
    .head-row {
      background-color: #a1a1a1;
      text-align: center;
      font-weight: bold;
    }
    .table-row {
      background-color: rgba(0, 0, 0, 0) !important;
    }
    .task-buttons {
      width: 767px;
      display: flex;
      justify-content: space-between;
      .save-task-button {
        margin: 10px 0 10px 0;
        color: white;
        background-color: #ff7f50;
        &:hover {
          background-color: rgb(253, 181, 142);
        }
      }
      .gen-task-button {
        margin: 10px 0 10px 10px;
        color: white;
        background-color: #ff7f50;
        &:hover {
          background-color: rgb(253, 181, 142);
        }
      }
      .add-task-button {
        margin: 10px 0 10px 0;
        &:hover {
          background-color: rgb(253, 181, 142);
        }
      }
      .validate-task-button {
        margin: 10px 0 10px 10px;
        color: white;
        background-color: #ff7f50;
        &:hover {
          background-color: rgb(253, 181, 142);
        }
      }
      .cancel-task-button {
        margin: 10px 0 10px 10px;
        color: white;
        background-color: #f5b039;
        &:hover {
          background-color: #f9d18c;
        }
      }
    }
  }
  .rt-thead {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .rt-th {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .MuiTableCell-root {
    font-size: 16px;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  }

  input, select {
    padding: 5px;
    border-radius: 5px;
    border: 1px solid #ddd;
    &:focus {
      border-color: #ff7f50;
      outline: none;
    }
  }
  // .numPages{
  //   background-color: #ffffff;
  // }
  .genPDF {
    position: absolute;
    right: 5vw;
    top: 10vh;
    .numPages {
      font-size: 20px;
      text-align: right;
    }
    .closeIcon {
      float: right;
      position: absolute;
      z-index: 10;
      right: 0px;
    }
    button {
      background-color: transparent;
    }
    .loadingPdf svg {
      height: 200px;
      position: absolute;
      top: 20vh;
      margin: 0 -130px;
    }
  }
  .docmenu {
    button {
      position: relative;
      margin-top: 6px;
      padding: 4px 0px 4px 0px;
      background-color: white;
      border-radius: 5px;
      margin-left: 50px;
      right: 10px;
    }
  }
}

@media (max-width: 1400px) {
  .quotaH {
    .quotatTables {
      font-size: 12px;
    }
  }
}

@media (max-width: 480px) {
  .quotaH {
    .code {
      input {
        margin-right: 40vw;
      }
    }

    .heures {
      display: block;

      div {
        margin-top: 10px;
      }

      button {
        margin-top: 10px;
        margin-bottom: 10px;
      }
    }

    .differences {
      input {
        margin-right: 35vw;
      }
    }
  }
}
