.appointment {
  .ndossier {
    position: absolute;
    display: flex;
    top: 160px;
    margin-left: 5rem;
    select {
      margin: 0 5px;
    }
  }
  .title {
    position: relative;
    margin-top: 100px;
    text-align: center;
  }
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;

    .email-editor {
      .btn-container {
        margin: 10px 0 20px 0;
        text-align: right;
        
        input,
        select {
          padding: 10px 5px;
          border-radius: 5px;
          border: 1px solid #ddd;
          &:focus {
            border-color: #ff7f50;
            outline: none;
          }
        }
        
        button {
          margin: 0 0 0 10px;
          background-color: #ff7f50;
  
          &:hover {
            background-color: #ff9b61;
          }
        }
        button:last-child {
          background-color: #f5b039;
          &:hover {
            background-color: #ffc154;
          }
        }
        button:disabled {
          background-color: #ccc;
          cursor: not-allowed;
        }
      }
    }

    .appointment-form {
      width: 700px;
      margin-top: 40px;
      border-collapse: collapse;
      margin-bottom: 25px;
      box-shadow: 0 0 10px rgb(122, 122, 122);

      .custom-cell {
        border: 1px solid #ddd;
        background-color: #f5f5f5;

        &:hover {
          background-color: #eee;
        }

        td:first-child {
          padding: 15px;
        }

        input {
          width: 93%;
          padding: 8px;
          border: none;
        }

        input[type="date"] {
          padding: 8px;
          width: 40%;
          margin-right: 5px;
          border: none;
        }

        input[type="time"] {
          padding: 8px;
          width: 70px;
          margin: 0 5px;
          border: none;
        }

        input[type="checkbox"] {
          margin: 15px 0;
          width: 5%;
          transform: scale(1.25);
        }

        .buttons-container {
          text-align: end;
          button {
            margin: 0 5px;
            background-color: #ff7f50;

            &:hover {
              background-color: #ff9b61;
            }
          }
          button:last-child {
            background-color: #f5b039;
            &:hover {
              background-color: #ffc154;
            }
          }
        }
      }

      .custom-cell-title {
        border: 1px solid #ddd;
        text-align: center;
        color: #f86733;
        background-color: #dfdfdf;
        .custom-cell-label {
          padding: 15px;
        }
      }
    }
    .appointment-list {
      width: 1450px;
      border-collapse: collapse;
      margin-bottom: 25px;
      box-shadow: 0 0 10px rgb(122, 122, 122);

      .custom-cell {
        border: 1px solid #ddd;
        background-color: #f5f5f5;

        &:hover {
          background-color: #eee;
        }

        td:first-child {
          width: 50px;
          padding: 15px;
        }

        input {
          width: 90%;
          padding: 8px;
          border: none;
        }

        input[type="date"] {
          padding: 8px;
          width: 40%;
          margin-right: 5px;
          border: none;
        }

        input[type="time"] {
          padding: 8px;
          width: 50px;
          margin: 0 5px;
          border: none;
        }

        .buttons-container {
          text-align: end;
          button {
            margin: 0 5px;
            background-color: #ff7f50;

            &:hover {
              background-color: #ff9b61;
            }
          }
          button:last-child {
            background-color: #f5b039;
            &:hover {
              background-color: #ffc154;
            }
          }
        }
      }

      .custom-cell-title {
        border: 1px solid #ddd;
        text-align: center;
        color: #f86733;
        background-color: #dfdfdf;
        .custom-cell-label {
          border-block-end: 1px solid #c2c1c1;
          padding: 15px;
        }
      }

      .custom-cell-thead {
        border: 1px solid #c2c2c2fd;
        text-align: center;
        background-color: #c2c2c2fd;
        .custom-cell-label {
          padding: 15px;
        }
      }
    }
    .button-msg {
      margin: 20px auto 10px auto;
      display: flex;
      flex-direction: column;

      .text-field {
        width: 250px;
        margin: 3px 0;
        background-color: #ffb129;
        border-radius: 5px;
        &:nth-child(2) {
          margin-bottom: 40px;
        }
        &:nth-child(6) {
          width: 100%;
        }
      }
      .password-toggle-btn {
        width: 35px;
        height: 35px;
      }
      .msg-field {
        width: 575px;
        margin: 3px 0;
        background-color: #fda40b;
        border-radius: 5px;
        .MuiOutlinedInput-multiline {
          padding: 10px;
        }
      }

      .group-btn {
        display: flex;
        .spinner {
          margin-right: 10px;
        }
        button {
          color: white;
          background-color: #ff7f50;
          &:hover {
            background-color: #ff9b61;
          }
          &:nth-last-child(1) {
            margin-left: 7px;
          }
        }
      }
      button:disabled {
        background-color: #ccc;
        cursor: not-allowed;
      }
    }
  }

  button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
}
