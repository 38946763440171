.contratData {
  .ndossier {
    position: absolute;
    display: flex;
    top: 190px;
    margin-left: 5rem;
  }
  .title {
    position: relative;
    margin-top: 80px;
    text-align: center;
  }
  .data {
    .table-data {
      width: 650px;
      margin: 100px auto auto auto;
    }
    .head-row {
      .MuiTableCell-root {
        text-align: center;
        font-weight: bold;
        font-size: 22px;
        color: #fff;
        background-color: #C0504D;
      }
    }
    .subtable-btn {
      display: flex;
      justify-content: flex-end;
      .btn-valid {
        color: white;
        background-color: #ff7f50;
        margin: 10px 5px 30px 5px;
        &:hover {
          background-color: #ff9b61;
        }
      }
      .btn-reset {
        color: white;
        background-color: #f5b039;
        margin: 10px 0 30px 5px;
        &:hover {
          background-color: #ffbf50;
        }
      }
      button:disabled {
        background-color: #ccc;
        cursor: not-allowed;
      }
      .spinner {
        width: 20px;
        margin: 10px 0 0 -100px;
      }
    }
    input {
      width: calc(100% - 20px);
      padding: 8px;
      border: 1px solid #ddd;
      border-radius: 4px;
      &:focus {
        border-color: #ff7f50;
        outline: none;
      }
    }
    .react-autosuggest__container {
      position: relative;
    }
    .react-autosuggest__input {
      width: calc(100% - 20px);
    }
    .react-autosuggest__input--open {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
    .react-autosuggest__suggestions-container {
      display: none;
    }
    .react-autosuggest__suggestions-container--open {
      display: block;
      position: absolute;
      width: inherit;
      text-align: center;
      top: 22px;
      left: -2px;
      border: 1px solid #aaa;
      background-color: #fff;
      font-size: 14px;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      z-index: 2;
      // &:hover{
      //     background-color: #ddd;
      // }
    }
    .react-autosuggest__suggestions-list {
      margin: 0;
      padding: 0;
      list-style-type: none;
    }
    .react-autosuggest__suggestion {
      cursor: pointer;
      padding: 10px 20px;
    }

    .react-autosuggest__suggestion--highlighted {
      background-color: #ddd;
    }

    select {
      width: calc(100% - 2px);
      padding: 8px;
      border: 1px solid #ddd;
      border-radius: 4px;
      text-align: center;
      &:focus {
        border-color: #ff7f50;
        outline: none;
      }
    }
    .MuiPaper-root {
      box-shadow: 0 0 5px #7a7a7a;
      .MuiTableRow-root {
        .MuiTableCell-root {
          padding: 10px;
          font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
        }
        .MuiTableCell-root:first-child {
          width: 40%;
        }
      }
    }
  }
  .genPDF {
    position: absolute;
    right: 5vw;
    top: 25vh;
    .numPages {
      font-size: 20px;
      text-align: right;
    }
    .closeIcon {
      float: right;
      position: absolute;
      z-index: 10;
      right: 0px;
    }
    button {
      background-color: transparent;
    }
    .loadingPdf svg {
      height: 200px;
      position: absolute;
      top: 20vh;
      margin: 0 -130px;
    }
  }
}
@media (max-width: 600px) {
  .contratData {
    .data {
      display: 0px;
      flex-direction: column;
      position: relative;
      margin-top: 100px;
      margin-left: 0px;
      .head-row {
        .MuiTableCell-root {
          text-align: center;
          font-weight: bold;
          font-size: 22px;
          color: #fff;
          background-color: #777777;
        }
      }
      .subtable-btn {
        .btn-valid {
          color: white;
          background-color: #ff7f50;
          margin: 10px 10px 10px auto;
          &:hover {
            background-color: #ff9b61;
          }
        }
        .btn-reset {
          color: white;
          background-color: #f5b039;
          margin: 10px 0 10px 0;
          &:hover {
            background-color: #ffbf50;
          }
        }
        button:disabled {
          background-color: #ccc;
          cursor: not-allowed;
        }
        .spinner {
          width: 20px;
          margin: 10px 0 0 -100px;
        }
      }
      input {
        width: 100%;
      }
    }
  }
}
@media (min-width: 801px) and (max-width: 1080px) {
  .contratData {
    .title {
      position: relative;
      top: 0px;
      left: 0;
    }
  }
}
