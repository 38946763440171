.containterNotif
{
    color: black;
    position: relative;
    top:100px;
    width: 1050px;
    height: 450px;
    border: 3px solid #ccc;
    border-style: dotted;
    margin: auto;
    padding: 15px 25px;
    background-color: rgba(255, 127, 80, 0.8);
}
.scopetype{
    position: absolute;
    right:0px;
    top: 0px;
    border: 2px solid #ccc;
    border-style: dotted;
    margin: auto;
    padding: 5px 5px;
    background-color: rgba(255, 127, 80, 0.8);
    width: 1090px;

}
.message{
    position: absolute;
    top:150px;
    left:31px;
    font-size: 15px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;

input{
    width: 500px;
    height: 150px;
    }
}

.h1{
    text-decoration:underline;
    position: relative;
    top: 0px;
    left:450px;
    color:green

    ;
}
.h2{
    position: relative;
    top: 100px;
    left:30px;
    color: white;
    font-size: 15px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight : bold;
    //color: white;
}
.h3{
    position: relative;
    top: 347.5px;
    left:800px;
    font-size: 15px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 900;
    color:  white;
}
.h4{
    position:absolute;
    top: 420.5px;
    right:35px;
    font-size: 15px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 900;
    color:  white;
}
.h5{
    position:absolute;
    top: 420.5px;
    left:20px;
    width: 50px;
    font-size: 15px;
}
.buttonmail{
    position: absolute;
    top: 305px;
    left:450px;   
}
.p{
    position: relative;
    top: 140px;
    left:30px;
    width: 1000px;
    font-size: 15px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: bold;
    color:  black;  
}
