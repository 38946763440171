.react-chatbot-kit-chat-container {
  position: absolute;
  width: 275px;
  height:-10px ;
  right:  38px;
}
.react-chatbot-kit-chat-inner-container {
  position: absolute;
  top: 45px;
  background-color: #fff;
  border-radius: 3px;
  border-radius: 5px;
  height: 500px;
}
.react-chatbot-kit-chat-header {
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  background-color: #efefef;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;;
  display: flex;
  align-items: center;
  font-size: 0.85rem;
  color: #514f4f;
  padding: 12.5px;
  font-weight: bold;
}


.react-chatbot-kit-chat-input-container { 
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;

}

.react-chatbot-kit-chat-message-container {
  padding: 0 17.5px 10px 17.5px;
  overflow: scroll;
  height: 410px;
}

.react-chatbot-kit-chat-input {
  width: 100%;
  padding: 12.5px;
  border: none;
  font-size: 0.85rem;
  border-top: 1px solid orange;
  border-bottom-left-radius: 5px;
}

.react-chatbot-kit-chat-input-form {
  width: 100%;
  display: flex;
}

.react-chatbot-kit-chat-input::placeholder {
  color: #585858;
}

.react-chatbot-kit-chat-btn-send {
  background-color: #ff7f00;
  width: 100px;
  border: none;
  color: #fff;
  border-bottom-right-radius: 5px;
}

.react-chatbot-kit-chat-btn-send-icon {
  fill: #fff;
  width: 15px;
  margin: 0 auto;
}

.react-chatbot-kit-chat-bot-message-container {
  display: flex;
  margin: 15px 0;
  justify-content: flex-start;
}

.react-chatbot-kit-chat-bot-avatar-container {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-size: 1.2rem;
  margin-right: 12.5px;
  background-color: #d8d8d8;
  display: flex;
  justify-content: center;
  align-items: center;
}

.react-chatbot-kit-chat-bot-avatar-icon {
  fill: #494646;
  width: 22.5px;
  height: 22.5px;
}

.react-chatbot-kit-chat-bot-avatar-letter {
  color: #1d1d1d;
  margin: 0;
  padding: 0;
}

.react-chatbot-kit-chat-bot-message {
  background-color: #d3d3d3;
  padding: 10px;
  border-radius: 5px;
  font-size: 0.9rem;
  color: black;
  font-weight: medium;
  position: relative;
  width: 184.5px;
  margin-left: auto;
  text-align: left;
}

.react-chatbot-kit-chat-bot-message-arrow {
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-right: 8px solid #a9a9a9; 
  position: absolute;
  left: -7px;
  top: 13px;
}

.react-chatbot-kit-chat-bot-loading-icon-container {
  height: 17px;
  width: 25px;
}

.chatbot-loader-container {
  display: flex;
  width: 100%;
  justify-content: center;
}

#chatbot-loader #chatbot-loader-dot1 {
  animation: load 1s infinite;
}

#chatbot-loader #chatbot-loader-dot2 {
  animation: load 1s infinite;
  animation-delay: 0.2s;
}

#chatbot-loader #chatbot-loader-dot3 {
  animation: load 1s infinite;
  animation-delay: 0.4s;
}

@keyframes load {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.react-chatbot-kit-user-chat-message-container {
  display: flex;
  margin: 15px 0;
  justify-content: flex-end;
}

.react-chatbot-kit-user-avatar-container {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-left: 12.5px;
  background-color: #ff7f00;
  display: flex;
  justify-content: center;
  align-items: center;
}

.react-chatbot-kit-user-avatar-icon {
  fill: #fff;
  width: 15px;
  height: 15px;
}

.react-chatbot-kit-user-chat-message {
  background-color: #ff7f00;
  padding: 10px;
  border-radius: 5px;
  font-size: 0.9rem;
  color: white;
  font-weight: medium;
  position: relative;
  text-align: left;
}

.react-chatbot-kit-user-chat-message-arrow {
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 8px solid #ff7f50;
  position: absolute;
  right: -7px;
  top: 13px;
}

//options

.options-container {
  display: flex;
  flex-wrap: wrap;
}

.option-button {
  margin: 5px;
  border-radius: 25px;
  padding: 8px;
  border-color: #ff7f00;
  background: #ff7f00;
  color: white;
  text-align: center;
}

//Quiz  
.flashcard-container {
  padding: 15px;
  border-radius: 3px;
  background-color: #ff7f00;
  color: white;
}

.flashcard-button {
  background: white ;
  padding: 8px;
  color: black;
  border-radius: 3px;
  border-color: #dcdcdc;
  margin: 5px;
  width: 150px;
}
//Icon Chat bot
.message-bot{
position: fixed;
top: 923px;
left:1620px;
font-size: 22px;
color: #dcdcdc;
}
.message-bot-container{
  border-style: dotted;
  border-color: #dcdcdc  ;
  background-color:rgba(255, 127, 80, 0.8);
  width: 280px;
  height: 25px;
  position: fixed;
  top: 920px;
  left:1600px;
}
.title-chat{
  position: fixed;
  color: white;
  top: 900px;
  left:1690px;
}

.button-chat{
  position: fixed;
  top: 450px;
  left:1630px;
  width: 500px;
}