.facture-con {
  padding-bottom: 50px;
  .title {
    position: relative;
    margin-top: 100px;
    text-align: center;
  }

  .ndossier {
    position: inherit;
    margin: 0;
    margin-left: 5rem;
  }
  .status {
    display: flex;
    justify-content: center;
    .green-light {
      background-color: green;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      margin: 0 10px;
      border: #000000 solid 2px;
    }
    .yellow-light {
      background-color: rgb(253, 152, 0);
      width: 20px;
      height: 20px;
      border-radius: 50%;
      margin: 0 10px;
      border: #000000 solid 2px;
    }
    .red-light {
      background-color: rgb(255, 2, 2);
      width: 20px;
      height: 20px;
      border-radius: 50%;
      margin: 0 10px;
      border: #000000 solid 2px;
    }
    span {
      font-size: 20px;
    }
    div:nth-child(3) + span {
      margin-top: -3px;
    }
    div:nth-child(5) + span {
      margin-top: -3px;
    }
    .hidden {
      display: none;
    }
  }
  .switch-div26 {
    margin: 35px 0 19px 0;
    text-align: center;
    span {
      font-size: 18px;
    }
    .no-active {
      color: transparent;
    }
    .active {
      color: #fff;
      background-color: #610cff;
      padding: 9px;
      border-radius: 15px;
    }
    .MuiSwitch-colorPrimary.Mui-checked {
      color: #fff;
    }
  }
  .switch-div34 {
    margin: 41px 0 30px 0;
    text-align: center;
    span {
      font-size: 18px;
      color: #fff;
      background-color: #610cff;
      padding: 9px 28px;
      border-radius: 15px;
    }
  }

  .facture-condata {
    .table-data {
      width: 600px;
      margin: 0 auto 0 auto;
    }
    .head-row {
      .MuiTableCell-root {
        text-align: center;
        font-weight: bold;
        font-size: 22px;
        color: #fff;
        background-color: #777777;
      }
    }
    .MuiTableBody-root {
      .MuiTableRow-root {
        .MuiTableCell-root:first-child {
          width: 140px;
        }
      }
    }
    .subtable-btn {
      display: flex;
      justify-content: flex-end;
      .btn-valid {
        color: white;
        background-color: #ff7f50;
        margin: 10px 5px 30px 5px;
        &:hover {
          background-color: #ff9b61;
        }
      }
      .btn-reset {
        color: white;
        background-color: #f5b039;
        margin: 10px 0 30px 5px;
        &:hover {
          background-color: #ffbf50;
        }
      }
    }
    input {
      width: 50%;
    }
    .react-autosuggest__container {
      position: relative;
    }
    .react-autosuggest__input {
      width: max-content;
    }
    .react-autosuggest__input--open {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
    .react-autosuggest__suggestions-container {
      display: none;
    }
    .react-autosuggest__suggestions-container--open {
      display: block;
      position: absolute;
      width: inherit;
      text-align: center;
      top: 22px;
      left: -2px;
      border: 1px solid #aaa;
      background-color: #fff;
      font-size: 14px;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      z-index: 2;
      // &:hover{
      //     background-color: #ddd;
      // }
    }
    .react-autosuggest__suggestions-list {
      margin: 0;
      padding: 0;
      list-style-type: none;
    }
    .react-autosuggest__suggestion {
      cursor: pointer;
      padding: 10px 20px;
    }

    .react-autosuggest__suggestion--highlighted {
      background-color: #ddd;
    }
    .create-btn {
      margin: 10px 180px;
      background-color: rgba(7, 105, 7, 0.863);
      &:hover {
        background-color: rgba(58, 128, 51, 0.863);
      }
    }
  }

  .facture-readata {
    .table-data {
      width: 600px;
      margin: 40px auto 0 auto;
    }
    .head-row {
      .MuiTableCell-root {
        text-align: center;
        font-weight: bold;
        font-size: 22px;
        color: #fff;
        background-color: #777777;
      }
    }
    .MuiTableBody-root {
      .MuiTableRow-root {
        .MuiTableCell-root:first-child {
          width: 140px;
        }
      }
    }
    .subtable-btn {
      display: flex;
      justify-content: flex-end;
      .btn-valid {
        color: white;
        background-color: #ff7f50;
        margin: 10px 5px 30px 5px;
        &:hover {
          background-color: #ff9b61;
        }
      }
      .btn-reset {
        color: white;
        background-color: #f5b039;
        margin: 10px 0 30px 5px;
        &:hover {
          background-color: #ffbf50;
        }
      }
    }
    input {
      width: 50%;
    }
    .react-autosuggest__container {
      position: relative;
    }
    .react-autosuggest__input {
      width: max-content;
    }
    .react-autosuggest__input--open {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
    .react-autosuggest__suggestions-container {
      display: none;
    }
    .react-autosuggest__suggestions-container--open {
      display: block;
      position: absolute;
      width: inherit;
      text-align: center;
      top: 22px;
      left: -2px;
      border: 1px solid #aaa;
      background-color: #fff;
      font-size: 14px;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      z-index: 2;
      // &:hover{
      //     background-color: #ddd;
      // }
    }
    .react-autosuggest__suggestions-list {
      margin: 0;
      padding: 0;
      list-style-type: none;
    }
    .react-autosuggest__suggestion {
      cursor: pointer;
      padding: 10px 20px;
    }

    .react-autosuggest__suggestion--highlighted {
      background-color: #ddd;
    }
    .create-btn {
      margin: 10px 180px;
      background-color: rgba(7, 105, 7, 0.863);
      &:hover {
        background-color: rgba(58, 128, 51, 0.863);
      }
    }
  }

  .btns {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .btn {
    margin: 15px 20px 0 0;
    background-color: rgba(255, 127, 80, 0.73);
    &:hover {
      background-color: rgb(253, 181, 142);
    }
  }
  .gen-btn {
    margin-bottom: 20px;
    background-color: rgba(255, 127, 80, 0.73);
    &:hover {
      background-color: rgb(253, 181, 142);
    }
  }
  .facture-condetail {
    position: absolute;
    width: 700px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;

    .ReactTable .rt-thead .rt-th {
      font-weight: bold;
    }
    .ReactTable {
      .rt-thead.-header {
        pointer-events: none;
        .rt-resizer {
          pointer-events: visible;
        }
      }
      .rt-head {
        .rt-tr {
          font-weight: bold;
        }
      }

      .rt-table {
        background: white;
      }

      border: 2px solid black;
      .rt-tbody {
        background-color: white;

        .titleDevisCon2 {
          font-size: 15px;
          font-weight: bold;
        }

        .rt-tr-group:first-child {
          border-top: 1px solid black;
          border-bottom: 1px solid black;

          // .rt-td:first-child {
          //   border-right: 1px solid black;
          // }
        }

        .rt-tr-group:nth-child(2) {
          .rt-td:first-child {
            border-right: 1px solid black;
          }
        }
        .rt-tr-group:nth-child(3) {
          .rt-td:first-child {
            border-right: 1px solid black;
          }
        }

        .rt-tr-group:nth-child(4) {
          .rt-td:first-child {
            border-right: 1px solid black;
          }
        }

        .rt-tr-group:nth-child(5) {
          .rt-td:first-child {
            border-right: 1px solid black;
          }
        }
        .rt-tr-group:nth-child(6) {
          .rt-td:first-child {
            border-right: 1px solid black;
          }
        }

        .rt-tr-group:nth-child(7) {
          border-top: 1px solid black;
          border-bottom: 1px solid black;

          // .rt-td:first-child {
          //   border-right: 1px solid black;
          // }
        }

        .rt-tr-group:nth-child(8) {
          .rt-td:first-child {
            border-right: 1px solid black;
          }
        }

        .rt-tr-group:nth-child(9) {
          .rt-td:first-child {
            border-right: 1px solid black;
          }
        }

        .rt-tr-group:nth-child(10) {
          .rt-td:first-child {
            border-right: 1px solid black;
          }
        }
        .rt-tr-group:nth-child(11) {
          .rt-td:first-child {
            border-right: 1px solid black;
          }
        }

        .rt-tr-group:nth-child(12) {
          border-top: 1px solid black;

          // .rt-td:first-child {
          //   border-right: 1px solid black;
          // }
        }

        .rt-tr-group {
          border: none;
        }
      }
      .titleDevisRea {
        font-size: 15px;
        font-weight: bold;
      }
    }

    .devisConSuite {
      margin-top: 3vh;

      .ReactTable .rt-tr {
        border: none;
      }

      .ReactTable .rt-tbody .rt-tr-group:nth-child(1) {
        border: none;
        border-top: 1px solid;
      }
      .ReactTable .rt-tbody .rt-tr-group:nth-child(2) {
        .rt-td:first-child {
          border-right: 1px solid black;
        }
      }

      .ReactTable .rt-tbody .rt-tr-group:last-child {
        border-top: 1px solid black;
      }
    }

    .pagination-bottom {
      display: none;
    }
    .tva {
      display: flex;
      margin: 10px;
      .MuiSlider-root {
        position: relative;
        color: tomato;
        top: 0px;
        width: 15rem;
      }
    }
    .group-btn {
      display: flex;
      justify-content: flex-end;
      .gen-btn {
        margin: 0 0 20px 10px;
      }
    }
  }
  .facture-readetail {
    position: absolute;
    width: 700px;
    margin-top: 40px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;

    .ReactTable .rt-thead .rt-th {
      font-weight: bold;
    }
    .ReactTable {
      .rt-thead.-header {
        pointer-events: none;
        .rt-resizer {
          pointer-events: visible;
        }
      }
      .rt-head {
        .rt-tr {
          font-weight: bold;
        }
      }

      .rt-table {
        background: white;
      }

      border: 2px solid black;
      .rt-tbody {
        background-color: white;

        .titleDevisCon2 {
          font-size: 15px;
          font-weight: bold;
        }

        .rt-tr-group:first-child {
          border-top: 1px solid black;
          border-bottom: 1px solid black;

          // .rt-td:first-child {
          //   border-right: 1px solid black;
          // }
        }

        .rt-tr-group:nth-child(2) {
          .rt-td:first-child {
            border-right: 1px solid black;
          }
        }
        .rt-tr-group:nth-child(3) {
          .rt-td:first-child {
            border-right: 1px solid black;
          }
        }

        .rt-tr-group:nth-child(4) {
          .rt-td:first-child {
            border-right: 1px solid black;
          }
        }

        .rt-tr-group:nth-child(5) {
          border-top: 1px solid black;
          border-bottom: 1px solid black;
          // .rt-td:first-child {
          //   border-right: 1px solid black;
          // }
        }
        .rt-tr-group:nth-child(6) {
          .rt-td:first-child {
            border-right: 1px solid black;
          }
        }

        .rt-tr-group:nth-child(7) {
          .rt-td:first-child {
            border-right: 1px solid black;
          }
        }

        .rt-tr-group:nth-child(8) {
          .rt-td:first-child {
            border-right: 1px solid black;
          }
        }

        .rt-tr-group:nth-child(9) {
          border-top: 1px solid black;
          border-bottom: 1px solid black;
          // .rt-td:first-child {
          //   border-right: 1px solid black;
          // }
        }

        .rt-tr-group:nth-child(10) {
          .rt-td:first-child {
            border-right: 1px solid black;
          }
        }
        .rt-tr-group:nth-child(11) {
          .rt-td:first-child {
            border-right: 1px solid black;
          }
        }

        .rt-tr-group:nth-child(12) {
          .rt-td:first-child {
            border-right: 1px solid black;
          }
        }

        .rt-tr-group:nth-child(13) {
          border-top: 1px solid black;
          border-bottom: 1px solid black;
          // .rt-td:first-child {
          //   border-right: 1px solid black;
          // }
        }

        .rt-tr-group {
          border: none;
        }
      }
      .titleDevisRea {
        font-size: 15px;
        font-weight: bold;
      }
    }

    .devisConSuite {
      margin-top: 3vh;

      .ReactTable .rt-tr {
        border: none;
      }

      .ReactTable .rt-tbody .rt-tr-group:nth-child(1) {
        border: none;
        border-top: 1px solid;
      }
      .ReactTable .rt-tbody .rt-tr-group:nth-child(2) {
        .rt-td:first-child {
          border-right: 1px solid black;
        }
      }

      .ReactTable .rt-tbody .rt-tr-group:last-child {
        border-top: 1px solid black;
      }
    }

    .pagination-bottom {
      display: none;
    }
    .tva {
      display: flex;
      margin: 10px;
      .MuiSlider-root {
        position: relative;
        color: tomato;
        top: 0px;
        width: 15rem;
      }
    }
    .group-btn {
      display: flex;
      justify-content: flex-end;
      .gen-btn {
        margin: 0 0 20px 10px;
      }
    }
  }
  .MuiButton-root {
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  }
  .spinner {
    width: 20px;
    margin-top: -2px;
    position: block;
  }
  .genPDF {
    position: absolute;
    right: 5vw;
    top: 25vh;
    .numPages {
      font-size: 20px;
      text-align: right;
    }
    .closeIcon {
      float: right;
      position: absolute;
      z-index: 10;
      right: 0px;
    }
    button {
      background-color: transparent;
    }
    .loadingPdf svg {
      height: 200px;
      position: absolute;
      top: 20vh;
      margin: 0 -130px;
    }
  }
  button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
}
