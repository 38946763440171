.devisRea{
    position: absolute;
    width: 100%;
    .ndossier{
        position: relative;
        display: flex;
        top: 6vh;
        margin-left: 5vw;
        div{
            margin-left: 2vw;
        }
    }
    h1{
        margin-top: 120px;
        text-align: center;
    }
    .tauxh{
        position: relative;
        float: left;
        margin-top: 50px;
        margin-left: 20px;
        .MuiSlider-root{
            position: relative;
            color: tomato;
            top: 10px;
            width: 10rem;
            margin-right: 10px;
            margin-left: 10px;
        }
        input{
            width: 1.5rem;
        }
    }
    .duree{  
        position: relative;
        float: left;
        margin-top: 50px;
        margin-left: 20px;
        .MuiSlider-root{
            position: relative;
            color: tomato;
            top: 10px;
            width: 7rem;
            margin-right: 10px;
            margin-left: 10px;
        }
        input{
            width: 1.6rem;
        }
    }
    .devisTable{
        position: absolute;
        margin-top: 100px;
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        .ReactTable{
            height: 50vh;
            border: 1px solid black;
            .rt-thead.-header{
                background-color: rgb(191, 191, 191);
                pointer-events: none;
                border-bottom: 1px solid black;
                .rt-resizer{
                    pointer-events: visible;
                }
                .rt-tr{
                    display: flex;
                    align-items: center;
                    justify-content: center;                   
                    .rt-th.-cursor-pointer{
                        border-right: 1px solid rgb(191, 191, 191);;
                    }
                }
            }
            .rt-tbody{
                background-color: white;
                .rt-tr-group{
                    border-bottom: 1px solid black;
                    .rt-td:first-child{
                        font-size: 12px;
                    }
                    .rt-td:not(:first-child){
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                    input{
                        text-align: center;
                    }
                }
                .rt-tr-group:nth-child(6){
                    .rt-td:nth-child(3){
                        background-color: #fcaf93;
                        input{
                            background-color: #fcaf93;
                        }
                    }
                }
                .rt-tr-group:nth-child(7){
                    .rt-td:nth-child(3){
                        background-color: #fcaf93;
                        input{
                            background-color: #fcaf93;
                        }
                    }
                }
                .rt-tr-group:last-child{
                    background-color: rgb(191, 191, 191);;
                    border: none;
                    .rt-td:nth-child(1){
                        border: none;
                    }
                    .rt-td:nth-child(2){
                        border: none;
                    }
                    .rt-td:nth-child(3){
                        border: none;
                    }
                    .rt-td:nth-child(4){
                        input{
                            display: none;
                        }
                    }
                    .rt-td:nth-child(5){
                        input{
                            display: none;
                        }
                    }
                }
                .rt-td{
                    border-left: 1px solid black;
                }
                .rt-td:first-child{
                    border: none;
                }
            }
            .pagination-bottom{
                display: none;
            }
        }
    }
    button{
        margin: 3vh auto;
        color: aliceblue;
        background-color: #ff7f50;
        &:hover{
            background-color: #ff9872;
        }
    }
}
@media (max-width:850px){
    .devisRea{
        .operation{
            position: relative;
            top: 22vh;
        }
        .title{
            font-size: 18px;
        }
        .devisTable{
            font-size: 12px;
            top: 8vh;
        }
    }
}