.pre-diuo-page2 {
  .title {
    position: relative;
    margin-top: 100px;
    text-align: center;
  }

  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 25px;

    .custom-table {
      width: 851px;
      border-collapse: collapse;
      margin-bottom: 25px;
      box-shadow: 0 0 10px rgb(122, 122, 122);

      .custom-cell {
        border: 1px solid #ddd;
        background-color: #f5f5f5;
        &:hover {
          background-color: #eee;
        }

        td:first-child {
          padding: 10px;
        }

        td {
          text-align: center;
        }
      }

      .custom-cell-title {
        border: 1px solid #196DBD;
        text-align: center;
        color: #fff;
        background-color: #196DBD;
      }

      .custom-cell-label {
        padding: 15px;
      }
    }
    input[type="checkbox"] {
      transform: scale(1.25);
      -webkit-transform: scale(1.25);
      -moz-transform: scale(1.25);
      -ms-transform: scale(1.25);
      -o-transform: scale(1.25);
    }
  }

  .buttons-container {
    display: flex;
    justify-content: center;
    padding-bottom: 30px;

    button {
      margin: 0 5px;
      background-color: #ff7f50;

      &:hover {
        background-color: #ff9b61;
      }
    }
  }
  button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  .genPDF {
    position: absolute;
    right: 5vw;
    top: 25vh;
    .numPages {
      font-size: 20px;
      text-align: right;
    }
    .closeIcon {
      float: right;
      position: absolute;
      z-index: 10;
      right: 0px;
    }
    button {
      background-color: transparent;
    }
    .loadingPdf svg {
      height: 200px;
      margin: -500px -180px;
    }
    .react-pdf__Page__annotations {
      display: none;
    }
  }
}
