@keyframes showParam {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes showParam {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.headerco {
  z-index: 20;
  position: fixed;
  width: 100%;
  background-color: #ff7f50;
  height: 50px;
  color: white;
  box-shadow: 0px 0px 8px black;
  top: 0;

  .leftheader {
    font-size: 30px;
    float: left;
    margin-top: 5px;

    > span {
      margin-left: 2vw;
      cursor: pointer;
    }
  }
  .listWrapper {
    display: flex;
    height: 100%;
    width: 100%;
    transition: margin 0.9s;
    margin-left: -500px;
  }
  .listWrapper.is-list-enabled {
    margin-left: 0px;
  }
  .list {
    position: absolute;
    z-index: 10;
    margin-top: 5px;
    height: calc(100vh - 50px);
    width: 22vw;
    background-color: rgb(48, 48, 48);
    font-size: 18px;
    overflow-y: scroll;
    p {
      position: relative;
      font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
      cursor: pointer;
      padding: 10px 0px 10px 20px;
      transition: 0.3s;
      margin: 16px 0;

      span {
        position: absolute;
        left: 19vw;
      }
    }
    p:hover {
      background-color: rgb(19, 19, 19);
    }
    .listContact {
      p {
        display: none;
        font-size: 14px;
      }
    }
    .listContact.is-contact-enabled {
      background-color: rgb(37, 37, 37);
      margin-top: -20px;
      p {
        margin-top: -15px;
        display: flex;
      }
    }
    .listFac {
      p {
        display: none;
        font-size: 14px;
      }
    }
    .listFac.is-fac-enabled {
      background-color: rgb(37, 37, 37);
      margin-top: -20px;
      p {
        margin-top: -15px;
        display: flex;
      }
    }
    .listFacAmendment {
      p {
        display: none;
        font-size: 14px;
      }
    }
    .listFacAmendment.is-fac-amendment-enabled {
      background-color: rgb(37, 37, 37);
      margin-top: -20px;
      p {
        margin-top: -15px;
        display: flex;
      }
    }

    .listDevis {
      p {
        display: none;
        font-size: 14px;
      }
    }

    .listDevis.is-devis-enabled {
      background-color: rgb(37, 37, 37);
      margin-top: -20px;
      p {
        margin-top: -15px;
        display: flex;
      }
    }
    .listContrat {
      p {
        display: none;
        font-size: 14px;
      }
    }

    .listContrat.is-contrat-enabled {
      background-color: rgb(37, 37, 37);
      margin-top: -20px;
      p {
        margin-top: -15px;
        display: flex;
      }
    }
    .listPGC {
      p {
        display: none;
        font-size: 14px;
      }
    }

    .listPGC.is-pgc-enabled {
      background-color: rgb(37, 37, 37);
      margin-top: -20px;
      p {
        margin-top: -15px;
        display: flex;
      }
    }
    .listPreDiuo {
      p {
        display: none;
        font-size: 14px;
      }
    }
    
    .listPreDiuo.is-prediuo-enabled {
      background-color: rgb(37, 37, 37);
      margin-top: -20px;
      p {
        margin-top: -15px;
        display: flex;
      }
    }
    
    .listDiuo {
      p {
        display: none;
        font-size: 14px;
      }
    }
    
    .listDiuo.is-diuo-enabled {
      background-color: rgb(37, 37, 37);
      margin-top: -20px;
      p {
        margin-top: -15px;
        display: flex;
      }
    }

    .listVIC {
      p {
        display: none;
        font-size: 14px;
      }
    }

    .listVIC.is-vic-enabled {
      background-color: rgb(37, 37, 37);
      margin-top: -20px;
      p {
        margin-top: -15px;
        display: flex;
      }
    }
    .listHeures {
      p {
        display: none;
        font-size: 14px;
      }
    }

    .listHeures.is-heures-enabled {
      background-color: rgb(37, 37, 37);
      margin-top: -20px;
      p {
        margin-top: -15px;
        display: flex;
      }
    }
    .listCalendar {
      p {
        display: none;
        font-size: 14px;
      }
    }

    .listCalendar.is-calendar-enabled {
      background-color: rgb(37, 37, 37);
      margin-top: -20px;
      p {
        margin-top: -15px;
        display: flex;
      }
    }
    .listRj {
      p {
        display: none;
        font-size: 14px;
      }
    }

    .listRj.is-rj-enabled {
      background-color: rgb(37, 37, 37);
      margin-top: -20px;
      p {
        margin-top: -15px;
        display: flex;
      }
    }
    .listEmploymentLaw {
      p {
        display: none;
        font-size: 14px;
      }
    }

    .listEmploymentLaw.is-employmentlaw-enabled {
      background-color: rgb(37, 37, 37);
      margin-top: -20px;
      p {
        margin-top: -15px;
        display: flex;
      }
    }
  }

  .rightheader {
    float: right;
    display: flex;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    .notification {
      display: inline-flex;
      font-size: 30px;
      margin-right: 2.5vw;
      margin-top: 5px;
      cursor: pointer;
      width: fit-content;
      padding-left: 5px;
      padding-right: 5px;
      .downArrow {
        position: relative;
        font-size: 15px;
        margin-left: 10px;
        margin-top: 10px;
      }
      // &:hover{
      // }
    }
    .notification > .notif-not-active {
      display: none;
    }

    .notification > .notif-active {
      position: relative;
      height: fit-content;
      background-color: rgb(255, 45, 45);
      color: white;
      padding: 0px 4px 0px 4px;
      font-size: 10px;
      text-align: center;
      border-radius: 20px;
      margin-top: 22px;
      margin-left: -11px;
      cursor: initial;
    }
    .profileWrapper {
      height: 100%;
      width: 100%;
      .profile > .profParam {
        height: 0px;
        display: none;
        transition: height 1s;
      }
    }
    .profileWrapper.is-profile-enabled {
      .profile > .profParam {
        position: absolute;
        background-color: #ff7f50;
        display: block;
        text-align: center;
        animation-name: showParam;
        animation-duration: 1s;
        height: fit-content;
        top: 50px;
        padding-left: 10px;
        padding-right: 10px;
        p {
          width: 100%;
          display: flex;
          &:hover {
            cursor: pointer;
          }
          span {
            margin-right: 5px;
          }
        }
      }
    }

    .profile {
      display: flex;
      padding-left: 20px;
      padding-right: 10px;
      .styleIcon {
        margin-top: 9px;
        margin-right: 0.6vw;
        width: 35px;
        height: 35px;
        border-radius: 20px;
      }
      .styleNoIcon {
        margin-top: 10px;
        margin-right: 0.6vw;
        font-size: 30px;
      }
      .identity {
        font-size: 20px;
        margin-top: 10px;
        margin-right: 1.5vw;
        cursor: pointer;
      }
    }
    > span {
      font-size: 30px;
      margin-top: 10px;
      cursor: pointer;
    }

    > img {
      margin-top: 2px;
      width: 45px;
      height: 45px;
      margin-left: 1.5vw;
      margin-right: 1vw;
    }
    .logout2 {
      button {
        margin-top: 8px;
        color: white;
      }
    }
  }
}
@media (max-width: 1000px) {
  .headerco .rightheader .profile .styleIcon {
    height: 30px;
    width: 30px;
  }

  .profileWrapper.is-profile-enabled {
    .profile {
      .profParam {
        p {
          //padding: 5px 90px 5px 20px;
          padding-right: 0;
          padding-left: 5px;

          span {
            svg {
              font-size: 17px;
            }
            display: flex;
          }
        }
      }
    }
  }
}
@media (max-width: 820px) {
  .wrapper.is-list-enabled > .list {
    width: 100%;
    height: 1673px;
  }

  .headerco .list {
    width: 50%;
  }
  .headerco {
    .list > p > span {
      left: inherit;
      right: 8vw;
    }

    .leftheader {
      margin-top: 7px;
      span {
        font-size: 22px;
      }
    }

    .rightheader {
      margin-top: 2px;
      .notification {
        margin-right: 0.5vw;
      }

      // span {

      //     // font-size: 25px;

      // }

      .profile {
        padding-left: 0;
      }
    }
  }

  .headerco .rightheader .profile .identity {
    font-size: 15px;
  }
}
@media (max-width: 700px) {
  .list > p {
    font-size: 14px;
  }
}

@media (min-width: 1500px) {
  .rightheader {
    float: right;
  }
}

@media (max-width: 1024px) {
  .rightheader {
    float: right;
  }
}

@media (max-width: 480px) {
  .Toastify {
    .Toastify__toast-container {
      width: 50vw;
      padding: 0;
      margin: 0;
      left: 47vw;
    }
  }
}

@media (max-width: 400px) {
  .connexion > .headerco > .leftheader {
    margin-top: 15px;
    font-size: 20px;
  }
  .connexion > .headerco > .leftheader > span {
    margin-right: 4vw;
  }
  .connexion > .headerco > .rightheader > span {
    font-size: 20px;
    margin-top: 15px;
  }
  .connexion > .headerco > .rightheader > .notification {
    font-size: 20px;
    margin-top: 15px;
  }
  .connexion > .headerco > .rightheader > .notification > .notif-active {
    font-size: 6px;
    margin-top: 13px;
    margin-left: -8px;
  }
  .connexion > .headerco > .rightheader .profile > .styleIcon {
    width: 22px;
    height: 22px;
    margin-top: 15px;
    margin-right: 2vw;
  }
  .connexion > .headerco > .rightheader .profile > .styleNoIcon {
    font-size: 20px;
    margin-top: 15px;
    margin-right: 2vw;
  }
  .connexion > .headerco > .rightheader .profile > .identity {
    font-size: 12px;
    margin-top: 20px;
  }
  .connexion > .headerco > .rightheader .profile > .profParam {
    font-size: 10px;
    div > span {
      right: 1vw;
    }
  }
}
