.contact-list {
  width: 100%;
  display: flex;
  flex-direction: column;

  .title {
    position: relative;
    margin-top: 100px;
    text-align: center;
  }

  .search-bar {
    width: 300px;
    margin-bottom: 20px;
    margin: 0 auto 20px auto;
    input {
      width: 100%;
      padding: 10px;
      font-size: 16px;
      border: 1px solid #ccc;
      border-radius: 7px;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
      &:focus {
        border-color: #ff7f50;
        outline: none;
      }
    }
    .search-input-wrapper {
      position: relative;
      .clear-search {
        position: absolute;
        right: -10px;
        top: 50%;
        transform: translateY(-50%);
        background: #cfcfcf;
        border: none;
        cursor: pointer;
        color: #666666;
        font-size: 14px;
        z-index: 10;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        box-shadow: 0 0 3px #000;
      }
    }
  }

  .contact-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
    padding: 0 20px 20px 20px;

    .contact-card {
      position: relative;
      background: #fff;
      border: 1px solid #ddd;
      border-radius: 8px;
      padding: 20px;
      position: relative;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);

      .contact-info {
        margin-bottom: 45px;

        h3 {
          margin: 0 0 10px 0;
          color: #333;
        }

        p {
          margin: 5px 0;
          color: #666;
        }
      }

      .edit-button {
        position: absolute;
        padding: 6px 30px;
        bottom: 20px;
        right: 20px;
        background: #ff7f50;
        color: white;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        transition: background 0.3s;

        &:hover {
          background: #ff9b61;
        }
      }

      .edit-form {
        .form-group {
          margin-bottom: 10px;

          label {
            display: block;
            margin-bottom: 5px;
            color: #333;
          }

          input {
            width: calc(100% - 18px);
            &:focus {
              border-color: #ff7f50;
              outline: none;
            }
          }
          select {
            width: 100%;
            &:focus {
              border-color: #ff7f50;
              outline: none;
            }
          }

          input,
          select {
            padding: 8px;
            border: 1px solid #ddd;
            border-radius: 4px;
          }
        }

        .button-group {
          display: flex;
          gap: 10px;
          margin-top: 15px;

          button {
            flex: 1;
            padding: 6px 20px;
            border-radius: 4px;
            cursor: pointer;

            &:first-child {
              color: white;
              background: #ff7f50;
              &:hover {
                background: #ff9b61;
              }
            }

            &:last-child {
              background: #f5b039;
              color: white;
              &:hover {
                background: #ffc154;
              }
            }
          }
        }
      }
    }
  }
}
