.rj-validation {
  position: absolute;
  width: 100%;
  .rjmenu {
    position: relative;
    display: flex;
    top: 50px;
    margin-left: 5rem;
    .ndossier {
      top: 0;
    }
    .numero {
      margin-left: 5rem;
    }
    .advancement {
      margin-left: 5rem;
    }
  }
  .title {
    position: relative;
    margin-top: 100px;
    text-align: center;
  }
  .data {
    position: relative;
    top: 80px;
    text-align: center;
    button {
      margin: 20px 10px 20px 0;
      background-color: #ff7f50;

      &:hover {
        background-color: #ff9b61;
      }
    }
    button:disabled {
      background-color: #ccc;
      cursor: not-allowed;
    }
  }
  .spinner {
    width: 20px;
    margin-top: 17px;
    position: absolute;
  }
  .numPages {
    font-size: 20px;
    text-align: right;
  }
  .genPDF {
    position: absolute;
    right: 5vw;
    top: 25vh;
    .numPages {
      font-size: 20px;
      text-align: right;
    }
    .closeIcon {
      float: right;
      position: absolute;
      z-index: 10;
      right: 0px;
    }
    button {
      background-color: transparent;
    }
    .loadingPdf svg {
      height: 200px;
      position: absolute;
      top: 20vh;
      margin: 0 -130px;
    }
  }
  button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
}
