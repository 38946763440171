@use "./CompTable.scss" as *;
@use "./AffaireTable.scss" as *;

.page1 {
  position: absolute;
  width: 100%;
  // .docmenu{
  //     top: 8.7rem;
  //     right: 5rem;
  // }
  .spinner {
    width: 20px;
    margin-top: 17px;
    position: absolute;
  }
  .vicmenu {
    position: relative;
    display: flex;
    top: 50px;
    margin-left: 5rem;
    .ndossier {
      top: 0;
    }
    .lot {
      margin-left: 5rem;
    }
    .entrepriselist {
      margin-left: 5rem;
    }
  }
  .title {
    position: relative;
    margin-top: 100px;
    text-align: center;
  }
  .data {
    position: relative;
    top: 80px;
    margin: auto auto 110px 50px;

    .afftable {
      background-color: white;
      border: black solid 1px;
      width: 1300px;
      // .rt-tr-group{
      //     // border: 1px solid black;
      // }

      .rt-td {
        text-align: center;
        border: 1px solid black;
      }
      .-pagination {
        display: none;
      }
    }
    .comptable {
      background-color: white;
      border: solid 1px black;
      margin-top: 20px;
      width: 1300px;
      .rt-td {
        text-align: center;
      }
      .-pagination {
        display: none;
      }
      input[name="company"] {
        border: none;
        outline: none;
      }
      input[name="adresse"] {
        border: none;
        outline: none;
      }
      .rt-tr-group:first-child {
        border: none;
        .rt-td {
          border-right: solid 1px black;
          border-bottom: 1px solid black;
        }
        .rt-td:first-child {
          // border: solid 1px rgba(0,0,0,0.05);
          border: none;
          border-right: solid 1px black;
        }
        .rt-td:nth-child(3) {
          border-right: none;
        }
        .rt-td:nth-child(4) {
          border-right: none;
        }
      }
      .rt-tr-group:nth-child(2) {
        border: none;
        .rt-td {
          border-right: solid 1px black;
          border-bottom: 1px solid black;
        }
        .rt-td:first-child {
          // border: solid 1px rgba(0,0,0,0.05);
          border: none;
          border-right: solid 1px black;
        }
        .rt-td:nth-child(3) {
          border-right: none;
        }
        .rt-td:nth-child(4) {
          border-right: none;
        }
      }
      .rt-tr-group:nth-child(3) {
        border: none;
        .rt-td {
          border-right: solid 1px black;
          border-bottom: 1px solid black;
        }
        .rt-td:first-child {
          // border: solid 1px rgba(0,0,0,0.05);
          border: none;
          border-right: solid 1px black;
        }
      }
      .rt-tr-group:nth-child(4) {
        border: none;
        .rt-td {
          border-right: solid 1px black;
          border-bottom: 1px solid black;
        }
        .rt-td:first-child {
          // border: solid 1px rgba(0,0,0,0.05);
          font-size: 20px;
          color: red;
          border: none;
          border-right: solid 1px black;
        }
      }
      .rt-tr-group:nth-child(5) {
        border: none;
        .rt-td {
          border-right: solid 1px black;
          border-bottom: 1px solid black;
        }
        .rt-td:first-child {
          // border: solid 1px rgba(0,0,0,0.05);
          border: none;
          border-right: solid 1px black;
        }
      }
      .rt-tr-group:nth-child(6) {
        border: none;
        .rt-td {
          border-right: solid 1px black;
        }
        .rt-td:first-child {
          // border: solid 1px rgba(0,0,0,0.05);
          border: none;
          border-right: solid 1px black;
        }
      }
    }
    .travtable {
      margin-top: 20px;
      background-color: white;
      border: black solid 1px;
      width: 1300px;
      .rt-td {
        text-align: center;
      }
      .-pagination {
        display: none;
      }
      .rt-tr-group:first-child {
        border: none;
        .rt-td {
          border-right: solid 1px black;
          border-bottom: 1px solid black;
        }
        .rt-td:first-child {
          // border: solid 1px rgba(0,0,0,0.05);
          border: none;
          border-right: solid 1px black;
        }
      }
      .rt-tr-group:nth-child(2) {
        border: none;
        .rt-td {
          border-right: solid 1px black;
          border-bottom: 1px solid black;
        }
        .rt-td:first-child {
          // border: solid 1px rgba(0,0,0,0.05);
          border: none;
          border-right: solid 1px black;
        }
      }
      .rt-tr-group:nth-child(3) {
        border: none;
        .rt-td {
          border-right: solid 1px black;
          border-bottom: 1px solid black;
        }
        .rt-td:first-child {
          // border: solid 1px rgba(0,0,0,0.05);
          font-size: 20px;
          color: red;
          border: none;
          border-right: solid 1px black;
        }
        .rt-td:nth-child(3) {
          border-right: none;
        }
        .rt-td:nth-child(4) {
          border-right: none;
        }
      }
      .rt-tr-group:nth-child(4) {
        border: none;
        .rt-td {
          border-right: solid 1px black;
          border-bottom: 1px solid black;
        }
        .rt-td:first-child {
          // border: solid 1px rgba(0,0,0,0.05);
          border: none;
          border-right: solid 1px black;
        }
      }
      .rt-tr-group:nth-child(5) {
        border: none;
        .rt-td {
          border-right: solid 1px black;
        }
        .rt-td:first-child {
          // border: solid 1px rgba(0,0,0,0.05);
          border: none;
          border-right: solid 1px black;
        }
      }
    }
    button {
      margin-top: 20px;
      margin-right: 10px;
      background-color: #ff7f50;
      &:hover {
        background-color: #ff9b61;
      }
    }
    .react-autosuggest__container {
      position: absolute;
      width: 18.5%;
    }
    .react-autosuggest__input {
      width: 100%;
    }
    .react-autosuggest__input--open {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
    .react-autosuggest__suggestions-container {
      display: none;
    }
    .react-autosuggest__suggestions-container--open {
      display: block;
      position: absolute;
      width: 100%;
      text-align: center;
      top: 20px;
      left: 0px;
      border: 1px solid #aaa;
      background-color: #fff;
      font-size: 14px;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      z-index: 10;
      // &:hover{
      //     background-color: #ddd;
      // }
    }
    .react-autosuggest__suggestions-list {
      margin: 0;
      padding: 0;
      list-style-type: none;
    }
    .react-autosuggest__suggestion {
      cursor: pointer;
      padding: 10px 20px;
    }

    .react-autosuggest__suggestion--highlighted {
      background-color: #ddd;
    }
    button:disabled {
      background-color: #ccc;
      cursor: not-allowed;
    }
  }
  .genPDF {
    position: absolute;
    right: 5vw;
    top: 25vh;
    .numPages {
      font-size: 20px;
      text-align: right;
    }
    .closeIcon {
      float: right;
      position: absolute;
      z-index: 10;
      right: 0px;
    }
    button {
      background-color: transparent;
    }
    .loadingPdf svg {
      height: 200px;
      position: absolute;
      top: 20vh;
      margin: 0 -130px;
    }
  }
}
