.rdv {
    position: absolute;
    top: 0px;
    width: 20%;
    height: 246px;
    margin-top: 50px;
    border: solid 1px black;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 14px;

    > .allRDVs{
        height: 246px;
        margin-left: 5px;
        font-size: 10px;
        overflow-y: scroll;
        scrollbar-width: thin;
        scrollbar-color: rgb(209, 209, 209) transparent;
        scrollbar-track-color: rgb(209, 209, 209);
        span{
            background-color: white;
            padding: 3px;
            margin: 0 2px;
            border-radius: 7px;
        }
        p {
            margin: 8px 0;
        }
    }

    > .allRDVs::-webkit-scrollbar {
        width: 7px;
      }
      
    > .allRDVs::-webkit-scrollbar-track {
        background: transparent;
      }
      
    > .allRDVs::-webkit-scrollbar-thumb {
        background-color: rgb(209, 209, 209);
        border-radius: 20px;
      }

    .titrerdv{
        display: block;
        position: relative;
        color: white;
        text-align: center;
        background-color: #ff7f50;
        bottom: 20px;
        border-bottom: solid 1px black;
        width: auto;
        bottom:0;
        top:0;
        left:0;
        right:0;
        padding: 10px;
    
    }

    > span{
        position: relative;
        font-size: 50px;
        top: 0px;
    }
}
@media(max-width:1800px){
    .rdv .allRDVs{
        font-size: 10px;
        span{
            padding: 2px;
        }

    }
}
@media(max-width:1400px){
    .rdv{
        width: 25%;
    }
}
@media(max-width:700px){
    .rdv{
        width: 50%;
    }
}
@media(max-width:400px){
    .rdv {
        .allRDVs{
            font-size: 10px;
        }
    }
}