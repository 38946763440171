.mails-monitoring {
  padding-bottom: 20px;
  .title {
    position: relative;
    margin-top: 100px;
    text-align: center;
  }
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 50px 20px 0 20px;

    .filters {
      margin-bottom: 20px;
      padding: 15px;
      background-color: #f5f5f5;
      border-radius: 4px;
      border: 1px solid #ddd;
      box-shadow: 0 0 10px #7a7a7a;

      &-container {
        display: flex;
        gap: 20px;
        flex-wrap: wrap;

        &-item {
          display: flex;
          align-items: center;
          gap: 8px;
          cursor: pointer;

          input {
            width: 16px;
            height: 16px;
          }
        }
      }
    }
    .intervenants-list {
      width: 1300px;
      border-collapse: collapse;
      margin-bottom: 25px;
      box-shadow: 0 0 10px rgb(122, 122, 122);

      .custom-cell {
        border: 1px solid #ddd;
        background-color: #f5f5f5;

        &:hover {
          background-color: #eee;
        }

        td:first-child {
          padding: 15px;
        }

        input {
          width: 90%;
          padding: 8px;
          border: none;
        }

        input[type="date"] {
          padding: 8px;
          width: 40%;
          margin-right: 5px;
          border: none;
        }

        input[type="time"] {
          padding: 8px;
          width: 50px;
          margin: 0 5px;
          border: none;
        }

        .buttons-container {
          text-align: end;
          button {
            margin: 0 5px;
            background-color: #ff7f50;

            &:hover {
              background-color: #ff9b61;
            }
          }
          button:last-child {
            background-color: #f5b039;
            &:hover {
              background-color: #ffc154;
            }
          }
        }
      }

      .custom-cell-thead {
        border: 1px solid #c2c2c2fd;
        text-align: center;
        background-color: #c2c2c2fd;
        .custom-cell-label {
          padding: 15px;
        }
      }

      .custom-cell-title {
        border: 1px solid #ddd;
        text-align: center;
        color: #f86733;
        background-color: #dfdfdf;
        .custom-cell-label {
          border-block-end: 1px solid #c2c1c1;
          padding: 15px;
        }
      }
    }
    .email-editor {
      .btn-container {
        margin: 10px 0 20px 0;
        text-align: right;
        button {
          margin: 0 0 0 10px;
          background-color: #ff7f50;

          &:hover {
            background-color: #ff9b61;
          }

          &:last-child {
            background-color: #f5b039;
            &:hover {
              background-color: #ffc154;
            }
          }
        }
        button:disabled {
          background-color: #ccc;
          cursor: not-allowed;
        }
      }

      .button-msg {
        margin: 20px auto 10px auto;
        display: flex;
        flex-direction: column;

        .text-field {
          width: 250px;
          margin: 3px 0;
          background-color: #ffb129;
          border-radius: 5px;
          &:nth-child(2) {
            margin-bottom: 40px;
          }
          &:nth-child(6) {
            width: 100%;
          }
        }
        .password-toggle-btn {
          width: 35px;
          height: 35px;
        }
        .msg-field {
          width: 575px;
          margin: 3px 0;
          background-color: #fda40b;
          border-radius: 5px;
          .MuiOutlinedInput-multiline {
            padding: 10px;
          }
        }

        .group-btn {
          display: flex;
          .spinner {
            margin-right: 10px;
          }
          button {
            color: white;
            background-color: #ff7f50;
            &:hover {
              background-color: #ff9b61;
            }
            &:nth-last-child(1) {
              margin-left: 7px;
            }
          }
        }
        button:disabled {
          background-color: #ccc;
          cursor: not-allowed;
        }
      }
    }
  }
}
