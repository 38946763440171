.facture {
    height: 221px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 18px;
    border: solid 1px black;
    border-top: none;
    width: auto;
    bottom:0;
    top:0;
    left:0;
    right:0;
    > .faclabel{
        position: relative;
        top: 0px;
        left: 40%;
        font-size: 34px;
    }
    .ReactTable{
        top: 50px;
        height: 180px;
        .rt-tbody{
            padding: 0px;
        }
        .pagination-bottom{
            display: none;
        }
    }
}
