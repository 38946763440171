.contract-valid {
  .title {
    position: relative;
    margin-top: 80px;
    text-align: center;
  }
  .ndossier {
    margin-left: 5rem;
  }

  .container {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    .custom-table {
      width: 600px;
      border-collapse: collapse;
      margin-bottom: 15px;
      box-shadow: 0 0 10px #7a7a7a;

      .custom-cell-head {
        color: #fff;
        border: 1px solid #c0504d;
        background-color: #c0504d;
        th {
          padding: 20px;
          font-size: larger;
        }
      }

      .custom-cell {
        border: 1px solid #ddd;
        background-color: #f5f5f5;

        &:hover {
          background-color: #eee;
        }

        td {
          width: 270px;
        }

        td:first-child {
          width: 60px;
          padding: 10px;
          height: 35px;
        }
      }
    }

    input[type="checkbox"] {
      width: 17px;
      height: 17px;
    }

    input[type="date"] {
      padding: 8px;
      border-radius: 5px;
    }

    input[type="file"] {
      padding: 12px;
      width: 93%;
      border-radius: 4px;
    }

    input {
      border: 1px solid #ddd;
      &:focus {
        border-color: #ff7f50;
        outline: none;
      }
    }
  }

  .img-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      width: 132px;
      height: 150px;
      margin-bottom: -15px;
      cursor: pointer;
    }
    
    .img-shadow {
      position: absolute;
      top: 4px;
      left: 53%;
      transform: translateX(-50%);
      filter: blur(3px);
      opacity: 0.6;
      z-index: 0;
    }

    .img-main {
      position: relative;
      z-index: 1;
    }
  }

  .buttons-container {
    width: 600px;
    display: flex;
    justify-content: right;

    button {
      margin-left: 10px;
      background-color: #ff7f50;
      &:hover {
        background-color: #ff9b61;
      }
    }
  }

  button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }

  .genPDF {
    position: absolute;
    right: 5vw;
    top: 10vh;
    z-index: 9;
    .numPages {
      font-size: 20px;
      text-align: right;
    }
    .closeIcon {
      float: right;
      position: absolute;
      z-index: 10;
      right: 0px;
    }
    button {
      background-color: transparent;
    }
  }
}
