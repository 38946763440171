@keyframes fade{
    0% {opacity: 1;}
    50%   {opacity:0; }
  100% { opacity:1; }
}
@keyframes fadein{
    0% {opacity: 0;}
  100% { opacity:1; }
}
.facturation{
    position: absolute;
    animation-duration: 5s;
    animation-name: fade;
    animation-iteration-count: infinite;
    transition: none;
    margin-top: 520px;
    right: 13vw;
}
.facturation:hover{
    animation-duration: 2s;
    animation-name: fadein;
    animation-iteration-count: 1;
    transition:none;
    cursor: pointer;
}
.logofac{
    border-radius: 10%;
    height: 130px;
    width: 230px;
    
}
.flechehaut2{
    position: inherit;
    transform: rotate(180deg);
    height: 45px;
    bottom: 150px;
    left: 100px;
}
.facturation label{
    position: inherit;
    font-size: 20px;
    left: 77px;
    bottom: 220px;
}
@media (max-width:1000px){
    .facturation{
        margin-top: 480px;
        right: 9vw;
    }
    .logofac{
        width: 150px;
    }
    .flechehaut2{
        width: 30px;
        left: 60px;
    }
    .facturation > label{
        text-align: center;
        font-size: 15px;
        left: 40px;
    }
}
@media (max-width:700px){
    .facturation{
        margin-top: 430px;
        right: 6vw;
    }
    .logofac{
        width: 120px;
        height: 100px;
    }
    .flechehaut2{
        display: none;
    }
    .facturation > label {
        font-size: 12px;
        left: 32px;
        bottom: 0px;
        top: 110px;
    }
}
@media (max-width:500px){
    .facturation{
        margin-top: 540px;
        right: 20px;
    } 
}