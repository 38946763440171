@keyframes disappear {
  0% {
    opacity: 0;
  }
  100% {
    pointer-events: none;
    opacity: 0;
  }
}
@-webkit-keyframes disappear {
  0% {
    opacity: 1;
  }
  100% {
    pointer-events: none;
    opacity: 0;
  }
}
@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    pointer-events: visible;
    opacity: 1;
  }
}
@-webkit-keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    pointer-events: visible;
    opacity: 1;
  }
}
.notifWrapper {
  height: 100%;
  width: 100%;
  position: absolute;
  right: 15vw;
  top: 50px;
  color: white;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  height: fit-content;
  width: 250px;
  z-index: -1;
  text-align: center;
}
.notifWrapper.is-notif-enabled {
  .notifList {
    height: 0px;
    padding: 0px;
    div {
      display: none;
    }
  }
}

.toastifyTestNotification {
  display: none;
}
.notifList [class^="notif-closed"] {
  display: none;
  background-color: transparent;
}
.notifList {
  position: absolute;
  background-color: transparent;
  padding: 10px 10% 0px 10%;
  height: max-content;
  width: 80%;
  .notifButton {
    display: none;
    margin-bottom: 20px;
    border: 1px solid white;
    &:hover {
      cursor: pointer;
      background-color: #e2673b;
    }
  }
  [class^="notif"] {
    position: relative;
    background-color: rgba(255, 127, 80, 0.8);
    border: 1px solid black;
    height: max-content;
    margin-bottom: 10px;
    border-radius: 10px;
    font-size: 15px;
    padding: 15px 5px 15px 5px;
    .notifIcon {
      float: left;
      position: relative;
      background-color: transparent;
      bottom: 10px;
      font-size: 30px;
    }
    [class^="closeNotif"] {
      float: right;
      position: relative;
      margin-right: 5px;
      cursor: pointer;
    }
    p {
      position: relative;
      color: white;
      text-decoration: none;
      top: -5px;
      left: 0px;
      font-size: 16px;
      bottom: 0px;
      margin-bottom: 0px;
      span {
        position: relative;
        font-size: 16px;
      }
    }
  }
}

.tooltip {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  position: absolute;
  width: 290px;
  font-size: 15px;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 15px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  pointer-events: none;
  white-space: pre-wrap; /* Pour gérer les sauts de ligne */
  span {
    color: red;
    &:last-child {
      color: blue;
    }
  }
}

// @media (max-width:400px) {
//     .notifList [class^="notif"] {

//     }
// }

.react-calendar__navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: -9px;
}

.react-calendar__navigation button:hover {
  background-color: #0056b3;
}

.react-calendar__month-view__days__day--weekend {
  color: #ff5733;
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: #8a8a8a;
}

.react-calendar__tile--active {
  background-color: #007bff;
  color: #ffffff;
  border-radius: 4px;
}

.react-calendar__tile--active:hover {
  background-color: #0056b3;
}

.react-calendar__tile--now {
  background-color: #ffc107;
  color: #ffffff;
  border-radius: 4px;
}

.react-calendar__tile--now:hover {
  background-color: #e0a800;
}

.react-calendar {
  border: none;
}
