html{
    height: 100%;
    margin: 0px;
}
body{
    height: 100%;
    margin: 0px;
}
.login{
    background: linear-gradient(#f7ac2c, 50%,#e0dddd);
    margin: 0px;
}
.header{
    background-color: #ff7f50;
    color: black;
    width: 100%;
    height: 145px;
    justify-content: baseline;
    text-align: center;
}
.header img{
    margin-top: 1.5vh;
    height: 90px;
    width: 130px;
    padding-bottom: 0.5vw;
}
.text{
    color: #120639;
    font-size: 20px;
}
.form {
	position: absolute;
	border: solid 1px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
	margin-top: 11vh;
	padding: 0px 5vw 2vh 5vw;
	font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    text-align: center;
    max-width: min-content;
}
.form > span > img{
    width: 45px;
    height: 45px;
    border-radius: 20px;
}
.form > h1{
    font-size: 50px;
    margin-bottom: 20px;
}
.form > .pwd{
    span{
        position: absolute;
        margin-left: 10px;
        margin-top: 2.5px;
    }
}
.verified{
    position: relative;
    border: 1px solid black;
    text-align: center;
    margin-top: 150px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
}

@media(max-width:700px){
    .form{
        border: none;
    }
    .form > form > h3{
        font-size: 30px;
    }
    .form > form > img{
        width: 30px;
        height: 30px;
    }
}
@media screen and (max-width: 600px) and (max-height: 800px){
    .form{
        top: 170px;
    }
    .form > form > h3{
        font-size: 44px;
    }
    .form > form > .userIcon{
        font-size: 40px;
    }
    .mail > label{
        font-size: 12px;
    }
    .mail > input{
        width: 100px;
    }
    .pwd > label{
        font-size: 12px;
    }
    .pwd > input{
        width: 100px;
    }
}

.btn{
    position: relative;
    left: 10px ;
    top: 10px;
    margin: 10px;
    padding: 10px;
    background-color: #ff7f50;
    color: white;
    border-radius: 2px;
    border: none;
    font-size: 18px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
.btn:hover{
    background-color: rgb(141, 60, 6);
    cursor: pointer;
}
.footer{
    margin-top: 694px;
    background-color: #ff7f50;
    width: 100%;
    height: 130px;
}
.copyright {
	right: 6vw;
	position: absolute;
	font-size: 12px;
	color: white;
	margin-top: 60px;
}
.copyright > label{
    font-size: 12px;
}
@media (max-width:400px){
    .copyright{
        font-size: 8px;
        right: 40px;
    }
    .copyright > label{
        font-size: 8px;
    }
}