.activities {
    height: 218px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 18px;
    border: solid 1px black;
    border-top: none;
    width: auto;
    bottom:0;
    top:0;
    left:0;
    right:0;
    > .actlabel{
        position: relative;
        top: 0px;
        left: 40%;
        font-size: 30px;
    }
    .ReactTable{
        top: 0px;
        height: 180px;
        .rt-tbody{
            padding: 0px;
        }
        .pagination-bottom{
            display: none;
        }
    }
}
@media(max-width:1400px){
    .connexion > .activities{
        height: 100px;
    }
    .connexion > .activities > .titreact{
        padding: 10px 1vw 10px 1vw;
        font-size: 12px;
    }
    .connexion > .activities > p{
        font-size: 12px;
    }
    .connexion > .activities > span{
        font-size: 30px;
    }
}
@media(max-width:820px){
    .connexion > .activities {
        left: 0;
        margin-top: 470px;
        width: 100%;
        height: 150px;
        border-radius: 0px;
        border-top: solid 1px;
        border-bottom: solid 1px;
        padding: 30px 0vw 90px 0vw;
    }
    .connexion > .activities > .titreact{
        font-size: 15px;
    }
    .connexion > .activities > p{
        font-size: 15px;
    }
    .connexion > .activities > span{
        font-size: 30px;
    }
}