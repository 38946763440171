html {
  height: 100%;
  margin: 0px;
}
body {
  height: 100%;
  margin: 0px;
  .MuiPaper-root .MuiTableCell-root .MuiTypography-body1 {
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  }
}
div {
  // font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
.Accueil {
  background-image: url(./Pictures/Capture5.PNG);
  background-repeat: no-repeat;
  background-position: center;
  height: 100vh;
  background-size: cover;
}
.ERP {
  position: relative;
  color: white;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 10vh;
  left: 9.3%;
  top: 2.2%;
}
.bienvenue {
  position: relative;
  color: white;
  font-size: 5vh;
  text-align: center;
  background-color: #4f4b47;
  margin: 30vh 1.18vw 0px 57.9vw;
  padding-top: 1%;
  padding-bottom: 1%;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
.S-E-C {
  font-size: 8vh;
}
@media (max-width: 1400px) {
  .bienvenue {
    font-size: 3vh;
    margin: 9vh 60% 1vh 2vw;
  }
  .S-E-C {
    font-size: 4vh;
  }
}
@media (max-width: 600px) {
  .Accueil {
    background-position: 42.3%;
    max-height: 100vh;
  }
  .bienvenue {
    width: 70vw;
  }
}
@media (max-width: 400px) {
  .ERP {
    left: 20px;
  }
  .bienvenue {
    margin-top: 90px;
    width: 100vw;
    margin-left: 0px;
  }
}
@media (max-height: 500px) {
  .Accueil {
    min-height: 500px;
  }
  .ERP {
    font-size: 40px;
  }
  .bienvenue {
    font-size: 18px;
  }
  .S-E-C {
    font-size: 20px;
  }
}
